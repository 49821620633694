import _useVisibleMetrics_ReportingPlatform_metricsTable from "./__generated__/useVisibleMetrics_ReportingPlatform_metricsTable.graphql";
import _useVisibleMetrics_ReportingPlatform_report from "./__generated__/useVisibleMetrics_ReportingPlatform_report.graphql";
import _useVisibleMetrics_ReportingPlatform_company from "./__generated__/useVisibleMetrics_ReportingPlatform_company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { useReportMetricsData } from './useReportMetricsData';
import { useShouldHideCurrencyMetrics } from './useShouldHideCurrencyMetrics';
import CompanyFragment from './__generated__/useVisibleMetrics_ReportingPlatform_company.graphql';
import MetricsTableFragment from './__generated__/useVisibleMetrics_ReportingPlatform_metricsTable.graphql';
import ReportFragment from './__generated__/useVisibleMetrics_ReportingPlatform_report.graphql';
_useVisibleMetrics_ReportingPlatform_company;
_useVisibleMetrics_ReportingPlatform_report;
_useVisibleMetrics_ReportingPlatform_metricsTable;
export const useVisibleMetrics = _ref => {
  let {
    companyRef,
    reportRef,
    metricsTableRef,
    reportFilters,
    reportGroupings
  } = _ref;
  const companyData = useFragment(CompanyFragment, companyRef);
  const reportData = useFragment(ReportFragment, reportRef);
  const metricsTableData = useFragment(MetricsTableFragment, metricsTableRef);
  const reportMetrics = useReportMetricsData(metricsTableData);
  const {
    shouldHideCurrencyMetrics
  } = useShouldHideCurrencyMetrics({
    companyRef: companyData,
    reportRef: reportData,
    currentReportFilters: reportFilters,
    currentGroupings: reportGroupings
  });
  const visibleMetrics = (reportMetrics === null || reportMetrics === void 0 ? void 0 : reportMetrics.filter(metric => !(shouldHideCurrencyMetrics && metric.definition.dataType === 'METRIC_DATA_TYPE_CURRENCY'))) || [];
  return visibleMetrics;
};